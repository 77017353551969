import React, { Component } from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import { Form, Select, Option } from 'informed'
import { Layout, Helmet, Section, Container, Item } from 'components'
// import {
//   InstantSearch,
//   Hits,
//   SearchBox,
// } from 'react-instantsearch-dom'

class ResidentielPage extends Component {
  state = {
    sector: '',
    typeDeLogements: '',
    title: '',
  }

  filterFn = ({ node: { sector, typeDeLogements, title } }) =>
    (!this.state.sector || sector === this.state.sector) &&
    (!this.state.typeDeLogements ||
      _.includes(typeDeLogements, this.state.typeDeLogements)) &&
    (!this.state.title ||
      _.includes(title.toLowerCase(), this.state.title.toLowerCase()))

  render() {
    let buildings = _.get(
      this.props,
      'data.allContentfulListingResidentiel.edges'
    )
    let selectSector = _.keys(_.groupBy(buildings, 'node.sector'))
    let selectTypeDeLogements = ['3 1/2', '4 1/2', '5 1/2']
    let filteredBuildings = _.filter(buildings, this.filterFn)

    return (
      <Layout>
        <Helmet
          title="Nomade, gestion immobilière"
          description="Notre mission, assurer la qualité de vie de nos locataires, dans nos immeubles résidentiels, en offrant des espaces locatifs novateurs et un service-client exemplaire."
          url="/residentiel"
        />
        <Section halfdark>
          <Container>
            <h2>Trouver le logement qu’il vous faut!</h2>
            <Form
              className="filter-form"
              onChange={({ values }) => {
                this.setState({
                  ...values,
                })
              }}
            >
              <div className="field">
                <label htmlFor="sector">localisation</label>
                <Select field="sector" id="sector">
                  <Option value="">Voir tout</Option>
                  {_.map(selectSector, item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="field">
                <label htmlFor="typeDeLogements">nombre de pièces</label>
                <Select field="typeDeLogements" id="typeDeLogements">
                  <Option value="">Voir tout</Option>
                  {_.map(selectTypeDeLogements, item => (
                    <Option key={item} value={item}>
                      {item.toString().replace(' 1/2', '\u00BD')}
                    </Option>
                  ))}
                </Select>
              </div>
              {/*<div className="search">
                <Text field="title" className="" placeholder="rechercher" autoComplete="off" />
                <SearchIcon />
              </div>*/}
            </Form>
            <div>
              <div className="items">
                {filteredBuildings.length ? (
                  filteredBuildings.map(({ node: { ...item } }) => (
                    <Item key={item.id} {...item} />
                  ))
                ) : (
                  <h4>Aucun résultat</h4>
                )}
              </div>
            </div>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default ResidentielPage

export const pageQuery = graphql`
  {
    allContentfulListingResidentiel {
      edges {
        node {
          id
          slug
          sector: residentialListingSecteur
          title: residentialListingTitle
          images: residentialListingImages {
            id
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          date: residentialListingAvailability
          prix: residentialListingPrice
          typeDeLogements: residentialListingTypes
        }
      }
    }
  }
`
